@import 'base.sass';

html {
    body {
        .inicio,
        .contenedor {
            /*************** FIJOS ***********************/
            .wpcf7 {
                margin: 0px auto;
                height: 100%;
                background: #F7FAFE;
                border-radius: 2px;
                filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15)); }
            .wpcf7-form {
                padding: size(29) size(13);
                @media (min-width: $breakpoint1) {
                    padding: size(41) size(44); }
                .titulo-fcontacto {
                    margin-bottom: size(11);
                    @media (min-width: $breakpoint1) {
                        margin-bottom: size(11); }
                    font-family: $fuente;
                    font-weight: 600;
                    font-size: size(25);
                    line-height: size(30);
                    margin-bottom: size(7);
                    letter-spacing: -0.02em;
                    color: $color4; } }
            .wps-form {
                width: 100%;
                .wps-form-row {
                    >p {
                        margin: 0;
                        padding: 0; } } }
            .wps-form .wps-form-row {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                width: 100%;
                @media (min-width: $breakpoint1) {
                    flex-direction: row;
                    margin: 15px 0;
                    &:first-child {
                        padding: 0;
                        margin: 0; } } }
            .wps-form .wps-form-row .wpcf7-form-control {
                width: 100%;
                .wpcf7-list-item {
                    margin: initial; } }
            .wps-form .wps-form-column {
                flex: 1;
                width: 100%;
                padding: .5rem 0;
                @media (min-width: $breakpoint1) {
                    margin: 0 5px;
                    padding: 0; }
                label,
                .label-textarea {
                    margin-bottom: 5px;
                    margin-left: 15px;
                    font-size: size(16);
                    line-height: size(19);
                    font-weight: normal; }
                .text-lopd {
                    font-family: $fuente;
                    font-size: size(12);
                    line-height: size(26);
                    font-weight: 300;
                    letter-spacing: 0.1em;
                    @media (min-width: $breakpoint1) {
                        font-size: size(16);
                        line-height: size(30); }
                    color: $color1;
                    a:link,
                    a:visited {
                        text-decoration: none;
                        color: $color1;
                        transition: color 0.3s linear;
                        &:hover {
                            color: $color2; } } } }
            .wpcf7 input[type=text], .wpcf7 input[type=email], .wpcf7 input[type=tel] {
                height: size(62);
                font-size: size(16);
                line-height: size(19);
                font-weight: normal;
                color: $ctexto;
                background-color: $white;
                border: none;
                border-radius: size(2);
                padding: size(17);
                @media (min-width: $breakpoint1) {
                    height: size(43); }
                &:focus {
                    border: 1px solid $color2;
                    border-radius: 2px;
                    outline: none; } }
            .wpcf7 textarea {
                font-size: size(16);
                line-height: size(19);
                font-weight: normal;
                padding: size(17);
                border: none;
                color: $ctexto;
                border-radius: size(2);
                background-color: $white;
                max-height: 100px;
                resize: none;
                @media (min-width: $breakpoint1) {
                    padding: size(12) size(17); }
                &:focus {
                    border: 1px solid $color2;
                    border-radius: 2px;
                    outline: none; } }
            .wpcf7 input[type=checkbox] {
                margin-right: 5px;
                transform: scale(1.5);
                background-color: $white;
                &:focus {
                    border: 1px solid $color2;
                    border-radius: 2px;
                    outline: none; }
                &::checked {
                    color: $color2;
                    border: 1px solid rgba(0, 0, 0, 0.08);
                    outline: none; } }
            .wpcf7-select {
                display: block;
                height: 45px;
                padding: 0 15px !important;
                margin-top: 5px;
                padding: 5px;
                &:focus {
                    color: $color4;
                    border: 1.5px solid $color4;
                    outline: none; } }
            .wpcf7-list-item-label {
                margin-left: size(5);
                font-size: size(16);
                line-height: size(19);
                font-weight: normal;
                color: $color1;
                a:link,
                a:visited {
                    text-decoration: none;
                    color: $color1;
                    transition: color 0.3s linear;
                    &:hover {
                        color: $color4; } } }
            .wpcf7-submit {
                position: relative;
                display: block;
                cursor: pointer;
                border: none;
                padding: size(14);
                font-family: $fuente;
                font-size: size(18);
                line-height: size(22);
                font-weight: bold;
                color: #3C3C3C;
                background-color: $color3;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
                border-radius: 2px;
                transition: background-color .3s linear;
                &:hover {
                    color: $white;
                    background-color: #02C59E; } }
            .wpcf7-quiz-label {
                font-family: $fuente;
                font-size: size(18);
                color: $color1; }
            .wps-form-column.submit {
                margin-top: 10px;
                @include flex(center,nowrap,column,center,center); }
            .wpcf7-not-valid-tip {
                margin-top: 5px; }
            .wpcf7-response-output {
                background-color: $color1;
                text-align: center;
                border: none !important;
                padding: 0.8em;
                border-radius: 3px;
                color: $white; }
            .wpcf7-mail-sent-ok {
                background-color: $color4;
                border: none !important;
                padding: 0.8em;
                border-radius: 3px; }
            .wpcf7-acceptance {
                font-size: size(18); }

            /*************** REUTILIZABLES ***************/
            // Etiquetas H
            h1,
            h2,
            h3, {
                display: block;
                font-family: $fuente;
                font-weight: 700; }
            h1 {
                font-size: size(35);
                line-height: size(41);
                @media (min-width: $breakpoint2) {
                    font-size: size(45);
                    line-height: size(52); } }
            h2 {
                font-size: size(30);
                line-height: size(35);
                @media (min-width: $breakpoint2) {
                    font-size: size(36);
                    line-height: size(42); } }
            h3 {
                font-size: size(20);
                line-height: size(23);
                @media (min-width: $breakpoint2) {
                    font-size: size(25);
                    line-height: size(29); } }
            // Títulos
            %titulos {
                position: relative;
                margin-bottom: size(40);
                @media (min-width: $breakpoint2) {
                    margin-bottom: size(37); }
                &::after {
                    display: block;
                    position: absolute;
                    content: "";
                    bottom: -15px;
                    left: 0;
                    height: size(5);
                    width: size(87);
                    background-color: $color3; }
                @media (min-width: $breakpoint2) {
                    &::after {
                        width: size(95); } }
                .titulo {
                    font-family: $fuente;
                    font-size: size(25);
                    font-weight: 700;
                    line-height: size(29);
                    color: $color4;
                    @media (min-width: $breakpoint2) {
                        font-size: size(35);
                        line-height: size(41); } } }
            .titulos {
                @extend %titulos; }
            // Botones
            %boton {
                .boton {
                    display: block;
                    margin-top: size(25);
                    @include flex(center,nowrap,row,center,center);
                    @media (min-width: $breakpoint2) {
                        @include flex(flex-start,nowrap,row,center,flex-start); }
                    .boton_link {
                        position: relative;
                        min-width: size(153);
                        padding: size(14) size(37);
                        font-family: $fuente;
                        font-size: size(18);
                        line-height: size(21);
                        color: #3C3C3C;
                        font-weight: 700;
                        text-decoration: none;
                        background-color: $color3;
                        text-align: center;
                        transition: background-color .3s linear;
                        &:hover {
                            background-color: #02C59E; } } } }
            .contenido {
                position: relative;
                z-index: 1;
                .wrapper-blog-single,
                .page,
                .singular {
                    .entry,
                    .blog {
                        .the-content {
                            // Migas de Pan
                            .breadcrumb {
                                margin: 0 auto;
                                max-width: $wrap;
                                .breadcrumb-contenido {
                                    padding: size(5) size(20);
                                    @media (min-width: $breakpoint1) {
                                        padding: size(11) 0 size(40) 0; }
                                    .rank-math-breadcrumb {
                                        a:link {
                                            padding: 0 size(5);
                                            font-family: $fuente;
                                            font-weight: 300;
                                            font-size: size(10);
                                            line-height: size(14);
                                            text-decoration: none;
                                            color: $black;
                                            &:hover {
                                                transition: color .3s linear;
                                                color: $color1; }
                                            @media (min-width: $breakpoint1) {
                                                font-size: size(14);
                                                line-height: size(14); } }
                                        .last {
                                            margin-left: size(5); } } } }
                            .contenedor-texto {
                                display: block;
                                padding: size(50) size(20);
                                width: 100%;
                                max-width: $wrap;
                                margin: 0 auto; }
                            /*************** BLOQUES ***************/
                            // Bloque Vídeo
                            .b-bloque-video {
                                display: block;
                                width: 100%;
                                margin: size(60) auto;
                                .bloque-titulos {
                                    padding: size(50) size(20);
                                    .titulos-video {
                                        position: relative;
                                        @include flex(center,nowrap,row,center,center);
                                        &::after {
                                            display: block;
                                            content: "";
                                            bottom: -15px;
                                            left: 50%;
                                            height: size(7);
                                            width: size(81);
                                            transform: translate(-50%);
                                            position: absolute;
                                            background-color: $color1; }
                                        .titulo-video,
                                        .subtitulo-video {
                                            display: inline-block;
                                            font-family: $fuente;
                                            font-size: size(24);
                                            line-height: size(30);
                                            text-align: center;
                                            color: $black; }
                                        .titulo-video {
                                            font-weight: 500; }
                                        .subtitulo-video {
                                            font-weight: 300; } } }
                                .bloque-video.wrap {
                                    width: 100%;
                                    display: block;
                                    max-width: $wrap;
                                    @media (min-width: $breakpoint1) {
                                        padding: 0 size(25); }
                                    video {
                                        display: block;
                                        width: 100%;
                                        height: 250px;
                                        object-fit: cover;
                                        @media (min-width: $breakpoint1) {
                                            height: 450px; } } }
                                .bloque-video.full {
                                    width: 100%;
                                    display: block;
                                    video {
                                        display: block;
                                        width: 100vw;
                                        height: 250px;
                                        object-fit: cover;
                                        @media (min-width: $breakpoint1) {
                                            height: 450px; } } } }
                            // Separador Título
                            .separador-titulo {
                                .bloque-titulos {
                                    padding: size(50) size(20);
                                    .titulos-separador {
                                        position: relative;
                                        @include flex(center,nowrap,row,center,center);
                                        &::after {
                                            display: block;
                                            content: "";
                                            bottom: -15px;
                                            left: 50%;
                                            height: size(7);
                                            width: size(81);
                                            transform: translate(-50%);
                                            position: absolute;
                                            background-color: $color1; }
                                        .titulo-separador,
                                        .subtitulo-separador {
                                            display: inline-block;
                                            font-family: $fuente;
                                            font-size: size(24);
                                            line-height: size(30);
                                            text-align: center;
                                            color: $black; }
                                        .titulo-separador {
                                            font-weight: 500; }
                                        .subtitulo-separador {
                                            font-weight: 300; } } } }
                            // Bloque Galería
                            .bloque-galeria {
                                margin-top: size(27);
                                @media (min-width: $breakpoint1) {
                                    margin-top: size(45); }
                                .bloque-titulos {
                                    padding: size(50) size(20);
                                    .titulos-galeria {
                                        position: relative;
                                        @include flex(center,nowrap,row,center,center);
                                        &::after {
                                            display: block;
                                            content: "";
                                            bottom: -15px;
                                            left: 50%;
                                            height: size(7);
                                            width: size(81);
                                            transform: translate(-50%);
                                            position: absolute;
                                            background-color: $color1; }
                                        .titulo-galeria,
                                        .subtitulo-galeria {
                                            display: inline-block;
                                            font-family: $fuente;
                                            font-size: size(24);
                                            line-height: size(30);
                                            text-align: center;
                                            color: $black; }
                                        .titulo-galeria {
                                            font-weight: 500; }
                                        .subtitulo-galeria {
                                            font-weight: 300; } } }
                                .fotografias {
                                    width: 100%;
                                    .galeria {
                                        width: 100%;
                                        @include flex(center,nowrap,column,stretch,stretch);
                                        @media (min-width: $breakpoint1) {
                                            @include flex(center,wrap,row,stretch,stretch); }
                                        .galeria-item {
                                            flex: 1 1 100%;
                                            .img-link {
                                                position: relative;
                                                display: block;
                                                height: 100%;
                                                width: 100%;
                                                text-decoration: none;
                                                figure {
                                                    width: 100%;
                                                    height: 100%;
                                                    overflow: hidden;
                                                    img {
                                                        -webkit-transform: scale(1);
                                                        transform: scale(1);
                                                        -webkit-transition: 2s ease-in-out;
                                                        transition: 2s ease-in-out;
                                                        display: block; }
                                                    &:hover {
                                                        .galeria-img {
                                                            -webkit-transform: scale(1.3);
                                                            transform: scale(1.3); } }
                                                    .galeria-img {
                                                        display: block;
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: cover;
                                                        object-position: center;
                                                        min-height: 313px;
                                                        max-height: 313px;
                                                        @media (min-width: $breakpoint1) {
                                                            min-height: 300px;
                                                            max-height: 476px; } } } } }
                                        .galeria-item.columnas-2 {
                                            margin-bottom: size(17);
                                            @media (min-width: $breakpoint1) {
                                                flex: 1 1 50%;
                                                padding: size(17) size(23); } }
                                        .galeria-item.columnas-3 {
                                            margin-bottom: size(20);
                                            @media (min-width: $breakpoint1) {
                                                flex: 1 1 33%;
                                                padding: size(5) size(10); } } } } }
                            // Bloque Fichas
                            .bloque-fichas-contenedor {
                                position: relative;
                                @include flex(center,wrap,row,center,center);
                                width: 100%;
                                height: 100%;
                                z-index: 0;
                                padding: 0 size(20);
                                &::before {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    content: "";
                                    position: absolute;
                                    z-index: 100;
                                    top: 0;
                                    left: 0; }
                                >* {
                                    position: relative;
                                    z-index: 1000; }
                                .bloque-fichas {
                                    .texto-fichas {
                                        margin-top: size(56);
                                        @media (min-width: $breakpoint2) {
                                            margin-top: size(76); }
                                        .titulos {
                                            @extend %titulos;
                                            .titulo {
                                                font-size: size(25);
                                                line-height: size(30);
                                                color: $color4;
                                                @media (min-width: $breakpoint2) {
                                                    font-size: size(35);
                                                    line-height: size(42); } } }
                                        .texto-fichas__descripcion {
                                            font-size: size(18);
                                            line-height: size(26);
                                            font-weight: 400;
                                            color: $ctexto;
                                            font-family: $fuente;
                                            p {
                                                margin: size(18) 0;
                                                &:first-child {
                                                    margin-top: initial; }
                                                &:last-child {
                                                    margin-bottom: initial; } }
                                            // Listas
                                            ul {
                                                list-style: none;
                                                padding: 0;
                                                margin-left: 5px;
                                                li {
                                                    margin: 0.5em 0;
                                                    list-style: inherit;
                                                    &::before {
                                                        content: "\2022";
                                                        padding-right: 8px;
                                                        color: $color4;
                                                        font-size: size(23); } } }
                                            a:link,
                                            a:visited {
                                                &:hover {
                                                    color: $color4; } } } }
                                    .tipo1 {
                                        width: 100%;
                                        height: 100%;
                                        @include flex(center,wrap,column,center,center);
                                        margin-top: size(26);
                                        margin-bottom: size(26);
                                        @media (min-width: $breakpoint2) {
                                            overflow: initial;
                                            @include flex(center,wrap,row,stretch,stretch);
                                            margin-top: size(56);
                                            margin-bottom: size(56);
                                            gap: size(20) size(40); }
                                        .fichar {
                                            position: relative;
                                            width: 100%;
                                            padding: size(10) 0;
                                            @include flex(flex-start,nowrap,row,stretch,stretch);
                                            @media (min-width: $breakpoint2) {
                                                padding: size(20) 0;
                                                flex-basis: 31%;
                                                width: initial; }
                                            .fichar-imagen {
                                                width: 100%;
                                                object-fit: contain;
                                                object-position: center;
                                                display: inline-block;
                                                text-align: center;
                                                margin-right: size(13);
                                                min-width: size(56);
                                                max-width: size(56); }
                                            .fichar-contenido {
                                                @include flex(space-between,nowrap,column,stretch,stretch);
                                                .fichar-cabecera {
                                                    @include flex(flex-start,nowrap,row,center,center);
                                                    &:hover {
                                                        cursor: pointer;
                                                        .fichar-titulo {
                                                            color: $color3; } }
                                                    &:hover::after {
                                                        cursor: pointer;
                                                        color: $color4; }
                                                    .fichar-titulo {
                                                        margin-bottom: size(7);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(24);
                                                        font-weight: 700;
                                                        color: $color4;
                                                        text-decoration: none;
                                                        transition: color .3s linear;
                                                        max-width: size(230);
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint2) {
                                                            max-width: initial;
                                                            font-size: size(25);
                                                            line-height: size(29); } } }
                                                .changed {
                                                    &::after {
                                                        display: inline-block;
                                                        margin-left: size(10);
                                                        margin-top: -8px;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e913";
                                                        color: $color3;
                                                        transition: color .3s linear; } }
                                                .slide {
                                                    &::after {
                                                        display: inline-block;
                                                        margin-left: size(10);
                                                        margin-top: -8px;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e90c";
                                                        color: $color3;
                                                        transition: color .3s linear; } }
                                                .fichar-titulo {
                                                    position: relative;
                                                    margin-bottom: size(7);
                                                    font-family: $fuente;
                                                    font-size: size(21);
                                                    line-height: size(24);
                                                    text-decoration: none;
                                                    font-weight: bold;
                                                    color: $color4;
                                                    transition: color .3s linear;
                                                    @media (min-width: $breakpoint2) {
                                                        font-size: size(25);
                                                        line-height: size(29); }
                                                    a:link,
                                                    a:visited {
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(24);
                                                        text-decoration: none;
                                                        font-weight: bold;
                                                        color: $color4;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint2) {
                                                            font-size: size(25);
                                                            line-height: size(29); } } }
                                                .fichar-descripcion {
                                                    display: block;
                                                    overflow: hidden;
                                                    font-family: $fuente;
                                                    font-size: size(16);
                                                    line-height: size(23);
                                                    font-weight: 400;
                                                    color: $ctexto;
                                                    margin-top: size(12);
                                                    .fichar-link {
                                                        display: inline-block;
                                                        @include flex(flex-star,nowrap,row,center,center);
                                                        margin-top: size(10);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(30);
                                                        font-weight: bold;
                                                        text-decoration: none;
                                                        color: $color2;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        .icono {
                                                            margin-left: size(4); } } }
                                                .fichar-linkado {
                                                    .fichar-link {
                                                        display: inline-block;
                                                        @include flex(flex-star,nowrap,row,center,center);
                                                        margin-top: size(10);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(30);
                                                        font-weight: bold;
                                                        text-decoration: none;
                                                        color: $color2;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        .icono {
                                                            margin-left: size(4); } } } }
                                            .expander {
                                                .fichar-descripcion {
                                                    height: 0px; } } }
                                        .fichar4 {
                                            position: relative;
                                            width: 100%;
                                            padding: size(10) 0;
                                            @include flex(flex-start,nowrap,row,stretch,stretch);
                                            @media (min-width: $breakpoint2) {
                                                padding: size(20) 0;
                                                flex-basis: 22%;
                                                width: initial; }
                                            .fichar-imagen {
                                                width: 100%;
                                                object-fit: contain;
                                                object-position: center;
                                                display: inline-block;
                                                text-align: center;
                                                margin-right: size(13);
                                                min-width: size(56);
                                                max-width: size(56); }
                                            .fichar-contenido {
                                                @include flex(space-between,nowrap,column,stretch,stretch);
                                                .fichar-cabecera {
                                                    @include flex(flex-start,nowrap,row,center,center);
                                                    &:hover {
                                                        cursor: pointer;
                                                        .fichar-titulo {
                                                            color: $color3; } }
                                                    &:hover::after {
                                                        cursor: pointer;
                                                        color: $color4; }
                                                    .fichar-titulo {
                                                        margin-bottom: size(7);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(24);
                                                        text-decoration: none;
                                                        font-weight: bold;
                                                        color: $color4;
                                                        transition: color .3s linear;
                                                        max-width: size(230);
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint2) {
                                                            max-width: initial;
                                                            font-size: size(25);
                                                            line-height: size(29); } } }
                                                .changed {
                                                    &::after {
                                                        display: inline-block;
                                                        margin-left: size(10);
                                                        margin-top: -8px;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e913";
                                                        color: $color3;
                                                        transition: color .3s linear; } }
                                                .slide {
                                                    &::after {
                                                        display: inline-block;
                                                        margin-left: size(10);
                                                        margin-top: -8px;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e90c";
                                                        color: $color3;
                                                        transition: color .3s linear; } }
                                                .fichar-titulo {
                                                    position: relative;
                                                    margin-bottom: size(7);
                                                    font-family: $fuente;
                                                    font-size: size(21);
                                                    line-height: size(24);
                                                    text-decoration: none;
                                                    font-weight: bold;
                                                    color: $color4;
                                                    transition: color .3s linear;
                                                    @media (min-width: $breakpoint2) {
                                                        font-size: size(25);
                                                        line-height: size(29); }
                                                    a:link,
                                                    a:visited {
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(24);
                                                        text-decoration: none;
                                                        font-weight: bold;
                                                        color: $color4;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint2) {
                                                            font-size: size(25);
                                                            line-height: size(29); } } }
                                                .fichar-descripcion {
                                                    display: block;
                                                    overflow: hidden;
                                                    font-family: $fuente;
                                                    font-size: size(16);
                                                    line-height: size(23);
                                                    font-weight: 400;
                                                    color: $ctexto;
                                                    margin-top: size(12);
                                                    .fichar-link {
                                                        display: inline-block;
                                                        @include flex(flex-star,nowrap,row,center,center);
                                                        margin-top: size(10);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(30);
                                                        font-weight: bold;
                                                        text-decoration: none;
                                                        color: $color2;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        .icono {
                                                            margin-left: size(4); } } }
                                                .fichar-linkado {
                                                    .fichar-link {
                                                        display: inline-block;
                                                        @include flex(flex-star,nowrap,row,center,center);
                                                        margin-top: size(10);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(30);
                                                        font-weight: bold;
                                                        text-decoration: none;
                                                        color: $color2;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        .icono {
                                                            margin-left: size(4); } } } }
                                            .expander {
                                                .fichar-descripcion {
                                                    height: 0px; } } } }
                                    .tipo2 {
                                        width: 100%;
                                        height: 100%;
                                        @include flex(center,wrap,column,center,center);
                                        margin-top: size(26);
                                        margin-bottom: size(26);
                                        @media (min-width: $breakpoint2) {
                                            overflow: initial;
                                            @include flex(center,wrap,row,stretch,stretch);
                                            margin-top: size(56);
                                            margin-bottom: size(56);
                                            gap: size(40); }
                                        .fichar {
                                            position: relative;
                                            width: 100%;
                                            padding: size(10) 0;
                                            @include flex(flex-start,nowrap,row,stretch,stretch);
                                            @media (min-width: $breakpoint2) {
                                                @include flex(stretch,nowrap,column,stretch,center);
                                                padding: size(29);
                                                flex-basis: 31%;
                                                width: initial;
                                                background: $white;
                                                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
                                                border-radius: 10px; }
                                            .fichar-imagen {
                                                width: 100%;
                                                object-fit: contain;
                                                object-position: center;
                                                display: inline-block;
                                                text-align: center;
                                                margin-right: size(13);
                                                min-width: size(56);
                                                max-width: size(56); }
                                            .fichar-contenido {
                                                @media (min-width: $breakpoint2) {
                                                    text-align: center; }
                                                .fichar-cabecera {
                                                    @include flex(flex-start,nowrap,row,center,center);
                                                    &::after {
                                                        display: inline-block;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e90c";
                                                        position: absolute;
                                                        color: $color3;
                                                        right: size(5);
                                                        top: size(15);
                                                        transition: color .3s linear; }
                                                    &:hover {
                                                        cursor: pointer;
                                                        .fichar-titulo {
                                                            color: $color3; } }
                                                    &:hover::after {
                                                        cursor: pointer;
                                                        color: $color4; }
                                                    .fichar-titulo {
                                                        margin-bottom: size(7);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(24);
                                                        text-decoration: none;
                                                        font-weight: bold;
                                                        color: $color4;
                                                        transition: color .3s linear;
                                                        max-width: size(210);
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint2) {
                                                            max-width: initial;
                                                            font-size: size(25);
                                                            line-height: size(29); } } }
                                                .changed {
                                                    &::after {
                                                        display: inline-block;
                                                        margin-left: size(10);
                                                        margin-top: -8px;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e913";
                                                        color: $color3;
                                                        transition: color .3s linear; } }
                                                .slide {
                                                    &::after {
                                                        display: inline-block;
                                                        margin-left: size(10);
                                                        margin-top: -8px;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e90c";
                                                        color: $color3;
                                                        transition: color .3s linear; } }
                                                .fichar-titulo {
                                                    position: relative;
                                                    margin-bottom: size(7);
                                                    font-family: $fuente;
                                                    font-size: size(21);
                                                    line-height: size(24);
                                                    text-decoration: none;
                                                    font-weight: bold;
                                                    color: $color4;
                                                    transition: color .3s linear;
                                                    @media (min-width: $breakpoint2) {
                                                        font-size: size(25);
                                                        line-height: size(29); }
                                                    a:link,
                                                    a:visited {
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(24);
                                                        text-decoration: none;
                                                        font-weight: bold;
                                                        color: $color4;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint2) {
                                                            font-size: size(25);
                                                            line-height: size(29); } } }
                                                .fichar-descripcion {
                                                    display: block;
                                                    overflow: hidden;
                                                    font-family: $fuente;
                                                    font-size: size(16);
                                                    line-height: size(23);
                                                    font-weight: 400;
                                                    color: $ctexto;
                                                    margin-top: size(12);
                                                    .fichar-link {
                                                        display: inline-block;
                                                        @include flex(flex-star,nowrap,row,center,center);
                                                        margin-top: size(10);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(30);
                                                        font-weight: bold;
                                                        text-decoration: none;
                                                        color: $color2;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        .icono {
                                                            margin-left: size(4); } } } }
                                            .expander {
                                                .fichar-descripcion {
                                                    height: 0px; } } }
                                        .fichar4 {
                                            position: relative;
                                            width: 100%;
                                            padding: size(10) 0;
                                            @include flex(flex-start,nowrap,row,stretch,stretch);
                                            @media (min-width: $breakpoint2) {
                                                @include flex(stretch,nowrap,column,stretch,center);
                                                padding: size(29);
                                                flex-basis: 22%;
                                                width: initial;
                                                background: $white;
                                                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
                                                border-radius: 10px; }
                                            .fichar-imagen {
                                                width: 100%;
                                                object-fit: contain;
                                                object-position: center;
                                                display: inline-block;
                                                text-align: center;
                                                margin-right: size(13);
                                                min-width: size(56);
                                                max-width: size(56); }
                                            .fichar-contenido {
                                                @media (min-width: $breakpoint2) {
                                                    text-align: center; }
                                                .fichar-cabecera {
                                                    &:hover {
                                                        cursor: pointer;
                                                        .fichar-titulo {
                                                            color: $color3; } }
                                                    &:hover::after {
                                                        cursor: pointer;
                                                        color: $color4; }
                                                    .fichar-titulo {
                                                        margin-bottom: size(7);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(24);
                                                        text-decoration: none;
                                                        font-weight: bold;
                                                        color: $color4;
                                                        transition: color .3s linear;
                                                        max-width: size(210);
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint2) {
                                                            max-width: initial;
                                                            font-size: size(25);
                                                            line-height: size(29); } } }
                                                .changed {
                                                    &::after {
                                                        display: inline-block;
                                                        margin-left: size(10);
                                                        margin-top: -8px;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e913";
                                                        color: $color3;
                                                        transition: color .3s linear; } }
                                                .slide {
                                                    &::after {
                                                        display: inline-block;
                                                        margin-left: size(10);
                                                        margin-top: -8px;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e90c";
                                                        color: $color3;
                                                        transition: color .3s linear; } }
                                                .fichar-titulo {
                                                    position: relative;
                                                    margin-bottom: size(7);
                                                    font-family: $fuente;
                                                    font-size: size(21);
                                                    line-height: size(24);
                                                    text-decoration: none;
                                                    font-weight: bold;
                                                    color: $color4;
                                                    transition: color .3s linear;
                                                    @media (min-width: $breakpoint2) {
                                                        font-size: size(25);
                                                        line-height: size(29); }
                                                    a:link,
                                                    a:visited {
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(24);
                                                        text-decoration: none;
                                                        font-weight: bold;
                                                        color: $color4;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint2) {
                                                            font-size: size(25);
                                                            line-height: size(29); } } }
                                                .fichar-descripcion {
                                                    display: block;
                                                    overflow: hidden;
                                                    font-family: $fuente;
                                                    font-size: size(16);
                                                    line-height: size(23);
                                                    font-weight: 400;
                                                    color: $ctexto;
                                                    margin-top: size(12);
                                                    .fichar-link {
                                                        display: inline-block;
                                                        @include flex(flex-star,nowrap,row,center,center);
                                                        margin-top: size(10);
                                                        font-family: $fuente;
                                                        font-size: size(21);
                                                        line-height: size(30);
                                                        font-weight: bold;
                                                        text-decoration: none;
                                                        color: $color2;
                                                        transition: color .3s linear;
                                                        &:hover {
                                                            color: $color3; }
                                                        .icono {
                                                            margin-left: size(4); } } } }
                                            .expander {
                                                .fichar-descripcion {
                                                    height: 0px; } } } } } }
                            // Bloque Cta
                            .bloque-cta-superior {
                                @media (min-width: $breakpoint1) {
                                    margin-top: -166px !important; } }
                            .bloque-cta {
                                position: relative;
                                width: 100%;
                                padding: 0 size(20);
                                .titulos {
                                    margin-bottom: size(10);
                                    .titulo {
                                        display: inline-block;
                                        font-family: $fuente;
                                        font-size: size(30);
                                        line-height: size(35);
                                        font-weight: 700;
                                        color: $color4;
                                        @media (min-width: $breakpoint1) {
                                            font-size: size(36);
                                            line-height: size(42); } }
                                    .subtitulo {
                                        margin-top: size(10);
                                        display: inline-block;
                                        font-family: $fuente;
                                        font-size: size(30);
                                        line-height: size(35);
                                        font-weight: 400;
                                        color: $white;
                                        @media (min-width: $breakpoint1) {
                                            font-size: size(36);
                                            line-height: size(42); } }
                                    &::after {
                                        display: none; } }
                                .grid-flex {
                                    position: relative;
                                    width: 100%;
                                    margin: 0 auto;
                                    @include flex(center,wrap,column,center,center);
                                    padding: size(55) size(20) size(20) size(20);
                                    max-width: size(1068);
                                    background: #00FFCC;
                                    border-radius: 15px;
                                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                    @media (min-width: $breakpoint1) {
                                        padding: size(40) 0 0 0;
                                        @include flex(space-between,wrap,row,stretch,flex-end); }
                                    .col {
                                        flex: 1;
                                        height: 100%; }
                                    .col-text {
                                        @include flex(center,wrap,row,center,center);
                                        height: 100%;
                                        order: 1;
                                        margin-top: size(43);
                                        text-align: center;
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial;
                                            text-align: initial;
                                            flex-basis: 64%;
                                            padding: 0 size(50); } }
                                    .aligner-item {
                                        width: 100%;
                                        .aligner-item__descripcion {
                                            margin-bottom: size(45);
                                            font-family: $fuente;
                                            font-size: size(16);
                                            line-height: size(23);
                                            font-weight: normal;
                                            color: $color4;
                                            p {
                                                margin: size(18) 0;
                                                &:first-child {
                                                    margin-top: initial; }
                                                &:last-child {
                                                    margin-bottom: initial; } }
                                            // Listas
                                            ul {
                                                list-style: none;
                                                padding: 0;
                                                margin-left: 5px;
                                                li {
                                                    margin: 0.5em 0;
                                                    list-style: inherit;
                                                    &::before {
                                                        content: "\2022";
                                                        padding-right: 8px;
                                                        color: $color4;
                                                        font-size: size(23); } } }
                                            a:link,
                                            a:visited {
                                                &:hover {
                                                    color: $white; } } }
                                        .boton {
                                            display: inline-block;
                                            margin-top: size(10);
                                            margin-bottom: size(40);
                                            .boton_link {
                                                display: inline-block;
                                                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
                                                border-radius: 3px;
                                                padding: size(10) size(23);
                                                font-family: $fuente;
                                                font-size: size(18);
                                                line-height: size(22);
                                                font-weight: 700;
                                                text-decoration: none;
                                                background-color: $white;
                                                color: #3C3C3C;
                                                transition: background-color .3s linear;
                                                &:hover {
                                                    color: $white;
                                                    background-color: $color4; } } } }
                                    .col-image {
                                        position: relative;
                                        z-index: 100;
                                        width: 100%;
                                        height: auto;
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial;
                                            margin-bottom: initial;
                                            padding: 0 size(20);
                                            flex-basis: 30%; }
                                        .imagen-bloque50 {
                                            position: relative;
                                            z-index: 100;
                                            display: block;
                                            width: 100%;
                                            max-width: 350px;
                                            height: 100%;
                                            object-fit: contain;
                                            object-position: center; }
                                        .col-image__container {
                                            display: block;
                                            position: relative;
                                            width: 100%; } }
                                    .col-left {
                                        order: 1; }
                                    .col-right {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            order: 2; } } } }
                            // Bloque Preguntas Frecuentes
                            .bloque-preguntas-frecuentes {
                                position: relative;
                                padding: 0 size(20);
                                @media (min-width: $breakpoint1) {
                                    padding: size(30) size(20); }
                                .grid-flex {
                                    position: relative;
                                    z-index: 10;
                                    @include flex(center,wrap,column,center,center);
                                    @media (min-width: $breakpoint1) {
                                        padding: initial;
                                        @include flex(space-between,wrap,row,stretch,stretch); }
                                    .col {
                                        flex: 1;
                                        height: 100%; }
                                    .col-text {
                                        @include flex(center,wrap,row,center,center);
                                        height: 100%;
                                        order: 1;
                                        margin-top: size(43);
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial; } }
                                    .aligner-item {
                                        width: 100%; }
                                    .titulos {
                                        @extend %titulos;
                                        .titulo {
                                            font-size: size(30);
                                            line-height: size(35);
                                            color: $color4;
                                            @media (min-width: $breakpoint1) {
                                                font-size: size(25);
                                                line-height: size(29); } } }
                                    .preguntas {
                                        width: 100%;
                                        height: 100%;
                                        @include flex(flex-start,wrap,column,stretch,stretch);
                                        @media (min-width: $breakpoint2) {
                                            overflow: initial;
                                            margin-bottom: size(56); }
                                        .preguntar {
                                            width: 100%;
                                            @include flex(flex-start,nowrap,row,stretch,stretch);
                                            .preguntar-contenido {
                                                padding-top: size(22);
                                                background-color: #F7FAFE;
                                                border-radius: size(2);
                                                margin-bottom: size(12);
                                                width: 100%;
                                                .preguntar-cabecera {
                                                    position: relative;
                                                    @include flex(flex-start,nowrap,row,center,center);
                                                    width: 100%;
                                                    padding: 0 size(20);
                                                    &::after {
                                                        display: inline-block;
                                                        font-family: $font_ico;
                                                        font-size: size(28);
                                                        content: "\e941";
                                                        position: absolute;
                                                        color: $color3;
                                                        right: size(20);
                                                        top: 50%;
                                                        transform: translate(0,-50%);
                                                        transition: color .3s linear; }
                                                    &:hover {
                                                        cursor: pointer;
                                                        .fichar-titulo {
                                                            color: $color3; } }
                                                    &:hover::after {
                                                        cursor: pointer;
                                                        color: $color4; }
                                                    .preguntar-titulo {
                                                        position: relative;
                                                        margin-bottom: size(7);
                                                        font-family: $fuente;
                                                        font-size: size(16);
                                                        line-height: size(20);
                                                        text-decoration: none;
                                                        font-weight: bold;
                                                        color: $color4;
                                                        transition: color .3s linear;
                                                        max-width: size(250);
                                                        &:hover {
                                                            color: $color3; }
                                                        @media (min-width: $breakpoint1) {
                                                            max-width: size(495);
                                                            font-size: size(18);
                                                            line-height: size(21); } } }
                                                .changed {
                                                    &::after {
                                                        transform: scaleY(-1);
                                                        top: 20%;
                                                        @media (min-width: $breakpoint1) {
                                                            top: -10%; } } }
                                                .preguntar-descripcion {
                                                    display: block;
                                                    overflow: hidden;
                                                    font-family: $fuente;
                                                    font-size: size(16);
                                                    line-height: size(23);
                                                    font-weight: 400;
                                                    color: $ctexto;
                                                    padding: 0 size(20);
                                                    margin-bottom: size(12);
                                                    @media (min-width: $breakpoint1) {
                                                        margin-top: size(12); } } }
                                            .expandir {
                                                .preguntar-descripcion {
                                                    display: block;
                                                    height: 0px; } } } }
                                    .col-image {
                                        position: relative;
                                        z-index: 100;
                                        width: 100%;
                                        height: auto;
                                        // margin-top: size(44)
                                        // margin-bottom: size(33)
                                        @include flex(center,nowrap,row,center,center);
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial;
                                            margin-bottom: initial;
                                            padding: 0 size(10); }
                                        .imagen-bloque50 {
                                            position: relative;
                                            z-index: 100;
                                            display: block;
                                            object-fit: contain;
                                            object-position: center; }
                                        .video-wrapper {
                                            overflow: hidden;
                                            position: relative;
                                            iframe {
                                                float: left; } } }
                                    .col-left {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            margin-right: size(40); } }
                                    .col-right {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            margin-left: size(40);
                                            order: 2; } } } }
                            // Bloque Editor 50 Formularios
                            .bloque-editor-50-contacto {
                                position: relative;
                                padding: size(25) size(20) size(25) size(20);
                                @media (min-width: $breakpoint1) {
                                    padding: size(40) size(20); }
                                .grid-flex {
                                    position: relative;
                                    z-index: 10;
                                    @include flex(center,wrap,column,center,center);
                                    padding: 0;
                                    @media (min-width: $breakpoint1) {
                                        @include flex(space-between,wrap,row,stretch,stretch); }
                                    .col {
                                        flex: 1;
                                        height: 100%; }
                                    .col-text {
                                        @include flex(center,wrap,row,center,center);
                                        height: 100%;
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            padding: 0 size(44); } }
                                    .aligner-item {
                                        width: 100%;
                                        .titulos {
                                            @extend %titulos;
                                            .titulo {
                                                font-size: size(25);
                                                line-height: size(30);
                                                color: $color4;
                                                @media (min-width: $breakpoint2) {
                                                    font-size: size(35);
                                                    line-height: size(42); } } }
                                        .aligner-item__descripcion {
                                            font-family: $fuente;
                                            font-size: size(18);
                                            line-height: size(25);
                                            font-weight: 400;
                                            p {
                                                margin: size(18) 0;
                                                &:first-child {
                                                    margin-top: initial; }
                                                &:last-child {
                                                    margin-bottom: initial; }
                                                img {
                                                    display: block;
                                                    width: 100%;
                                                    object-fit: cover;
                                                    object-position: center;
                                                    min-height: size(448);
                                                    max-height: size(448); } }
                                            // Listas
                                            ul {
                                                list-style: none;
                                                padding: 0;
                                                margin-left: 5px;
                                                li {
                                                    margin: 0.5em 0;
                                                    list-style: inherit;
                                                    &::before {
                                                        content: "\2022";
                                                        padding-right: 8px;
                                                        color: $color4;
                                                        font-size: size(23); } } }
                                            a:link,
                                            a:visited {
                                                &:hover {
                                                    color: $color4; } } } }
                                    .col-contacto {
                                        .formulario-contacto {} }
                                    .col-left {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            margin-right: size(40); } }
                                    .col-right {
                                        order: 2;
                                        margin-top: size(40);
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial;
                                            order: 2; } } } }
                            // Bloque Editor 50 Formularios 2 con imagen de fondo
                            .bloque-editor-50-contacto-2 {
                                position: relative;
                                padding: size(30) size(20);
                                .grid-flex {
                                    position: relative;
                                    z-index: 10;
                                    @include flex(center,wrap,column,center,center);
                                    padding: 0;
                                    @media (min-width: $breakpoint1) {
                                        @include flex(space-between,wrap,row,stretch,stretch); }
                                    .col {
                                        flex: 1;
                                        height: 100%; }
                                    .col-text {
                                        @include flex(center,wrap,row,center,center);
                                        height: 100%;
                                        order: 1;
                                        margin-top: size(43);
                                        @media (min-width: $breakpoint1) {
                                            @include flex(flex-start,wrap,row,stretch,stretch);
                                            margin-top: initial;
                                            padding: 0 size(44); }
                                        .logo-img {
                                            display: block;
                                            margin: size(27) 0;
                                            min-width: size(200);
                                            max-width: size(200); } }
                                    .aligner-item {
                                        width: 100%;
                                        .titulos {
                                            @extend %titulos;
                                            &::after {
                                                display: none; }
                                            .titulo {
                                                font-size: size(30);
                                                line-height: size(35);
                                                font-weight: 400;
                                                font-style: italic;
                                                color: $white; } }
                                        .aligner-item__descripcion {
                                            font-family: $fuente;
                                            font-size: size(18);
                                            line-height: size(25);
                                            font-weight: 400;
                                            color: $white;
                                            p {
                                                margin: size(18) 0;
                                                &:last-child {
                                                    margin-bottom: initial; } }
                                            // Listas
                                            ul {
                                                list-style: none;
                                                padding: 0;
                                                margin-left: 5px;
                                                li {
                                                    margin: 0.5em 0;
                                                    list-style: inherit;
                                                    &::before {
                                                        content: "\2022";
                                                        padding-right: 8px;
                                                        color: $white;
                                                        font-size: size(23); } } }
                                            a:link,
                                            a:visited {
                                                &:hover {
                                                    color: $color3; } } } }
                                    .col-contacto {
                                        .formulario-contacto {} }
                                    .col-left {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            margin-right: size(40); } }
                                    .col-right {
                                        order: 2;
                                        margin-top: size(40);
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial;
                                            margin-left: size(40);
                                            order: 2; } } } }
                            // Bloque Editor 50%
                            .bloque-editor-50 {
                                position: relative;
                                padding: size(30) size(20);
                                .titulos {
                                    @extend %titulos;
                                    .titulo {
                                        font-size: size(25);
                                        line-height: size(29);
                                        @media (min-width: $breakpoint2) {
                                            font-size: size(35);
                                            line-height: size(41); } } }
                                .grid-flex {
                                    position: relative;
                                    z-index: 10;
                                    @include flex(center,wrap,column,center,center);
                                    padding: 0;
                                    @media (min-width: $breakpoint1) {
                                        @include flex(space-between,wrap,row,stretch,center); }
                                    .col {
                                        flex: 1;
                                        height: 100%; }
                                    .col-text {
                                        @include flex(center,wrap,row,center,center);
                                        height: 100%;
                                        order: 1;
                                        margin-top: size(43);
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial; } }
                                    .aligner-item {
                                        width: 100%;
                                        .aligner-item__descripcion {
                                            font-family: $fuente;
                                            font-size: size(18);
                                            line-height: size(25);
                                            font-weight: 400;
                                            p {
                                                margin: size(18) 0;
                                                &:first-child {
                                                    margin-top: initial; }
                                                &:last-child {
                                                    margin-bottom: initial; } }
                                            // Listas
                                            ul {
                                                list-style: none;
                                                padding: 0;
                                                margin-left: 5px;
                                                li {
                                                    margin: 0.5em 0;
                                                    list-style: inherit;
                                                    &::before {
                                                        content: "\2022";
                                                        padding-right: 8px;
                                                        color: $color4;
                                                        font-size: size(23); } } }
                                            a:link,
                                            a:visited {
                                                &:hover {
                                                    color: $color4; } } } }
                                    .col-image {
                                        position: relative;
                                        z-index: 100;
                                        width: 100%;
                                        height: auto;
                                        // margin-top: size(44)
                                        // margin-bottom: size(33)
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial;
                                            margin-bottom: initial;
                                            padding: 0 size(10); }
                                        .imagen-bloque50 {
                                            position: relative;
                                            z-index: 100;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            object-position: center; }
                                        .col-image__container {
                                            display: block;
                                            position: relative;
                                            width: 100%; }
                                        .video-wrapper {
                                            overflow: hidden;
                                            position: relative;
                                            iframe {
                                                float: left; } } }
                                    .col-left {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            margin-right: size(40); } }
                                    .col-right {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            margin-left: size(40);
                                            order: 2; } } } }
                            // Bloque Editor 50% - Tabs
                            .bloque-editor-50-tabs {
                                position: relative;
                                .grid-flex {
                                    position: relative;
                                    z-index: 10;
                                    @include flex(center,wrap,column,center,center);
                                    padding: 0;
                                    @media (min-width: $breakpoint1) {
                                        @include flex(space-between,wrap,row,stretch,stretch); }
                                    .col {
                                        flex: 1;
                                        height: 100%; }
                                    .col-text {
                                        height: 100%;
                                        width: 100%;
                                        order: 1; }
                                    .aligner-item {
                                        width: 100%;
                                        .tabs {
                                            position: relative;
                                            margin: size(52) size(30) size(38) size(30);
                                            @media (min-width: $breakpoint1) {
                                                margin: size(52) size(34) size(38) size(34); } }
                                        .tabs > input,
                                        .tabs > span {
                                            position: absolute;
                                            top: 0;
                                            height: size(42);
                                            font-size: size(18);
                                            line-height: size(21);
                                            font-weight: bold;
                                            width: 49%;
                                            @media (min-width: $breakpoint1) {
                                                width: 31%; } }
                                        .tabs > input {
                                            position: absolute;
                                            cursor: pointer;
                                            filter: alpha(opacity=0);
                                            opacity: 0;
                                            z-index: 99; }
                                        .tabs > span {
                                            @include flex(center,nowrap,row,center,center);
                                            position: absolute;
                                            color: $color2;
                                            background-color: $white;
                                            border: 2px solid $color2;
                                            border-radius: size(51);
                                            text-align: center;
                                            overflow: hidden;
                                            padding: size(10);
                                            @media (min-width: $breakpoint1) {
                                                padding: size(5) size(10); } }
                                        .tabs > span i,
                                        .tabs > span {
                                            -webkit-transition: all .5s;
                                            -moz-transition: all .5s;
                                            -o-transition: all .5s;
                                            transition: all .5s; }
                                        .tabs > input:hover + span,
                                        .tabs > input:checked + span {
                                            color: $white;
                                            background-color: $color2; }
                                        #tab-1, #tab-1 + span {
                                            left: 0; }
                                        #tab-2, #tab-2 + span {
                                            left: 52%;
                                            @media (min-width: $breakpoint1) {
                                                left: 35%; } }
                                        #tab-3, #tab-3 + span {
                                            left: 0;
                                            top: size(50);
                                            @media (min-width: $breakpoint1) {
                                                left: 70%;
                                                top: initial; } }
                                        .tab-content {
                                            padding: size(105) size(20) size(20) size(20);
                                            @media (min-width: $breakpoint1) {
                                                padding: size(80) size(20) size(20); }
                                            width: 100%;
                                            min-height: size(386); }
                                        .tab-content section {
                                            width: 100%;
                                            display: none; }
                                        .tab-content section .titulos .titulo {
                                            color: $color4; }
                                        .tab-content section .texto-tab {
                                            font-family: $fuente;
                                            font-size: size(18);
                                            line-height: size(25);
                                            font-weight: 400;
                                            color: $ctexto;
                                            p {
                                                margin: size(18) 0;
                                                &:first-child {
                                                    margin-top: initial; }
                                                &:last-child {
                                                    margin-bottom: initial; } }
                                            // Listas
                                            ul {
                                                list-style: none;
                                                padding: 0;
                                                margin-left: 5px;
                                                li {
                                                    margin: 0.5em 0;
                                                    list-style: inherit;
                                                    &::before {
                                                        content: "\2022";
                                                        padding-right: 8px;
                                                        color: $color4;
                                                        font-size: size(23); } } }
                                            a:link,
                                            a:visited {
                                                &:hover {
                                                    color: $color4; } } }
                                        #tab-1:checked ~ .tab-content #tab-item-1 {
                                            display: block; }
                                        #tab-2:checked ~ .tab-content #tab-item-2 {
                                            display: block; }
                                        #tab-3:checked ~ .tab-content #tab-item-3 {
                                            display: block; }
                                        /* effect-1 */
                                        .effect-1 > input:checked + span {
                                            color: $white;
                                            background-color: $color2; } }
                                    .col-image {
                                        position: relative;
                                        z-index: 100;
                                        width: 100%;
                                        height: auto;
                                        .imagen-bloque50 {
                                            position: relative;
                                            z-index: 100;
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            object-position: center; }
                                        .col-image__container {
                                            display: block;
                                            position: relative;
                                            width: 100%; }
                                        .video-wrapper {
                                            overflow: hidden;
                                            position: relative;
                                            iframe {
                                                float: left; } } }
                                    .col-left {
                                        order: 2;
                                        @media (min-width: $breakpoint1) {
                                            order: 1; } }
                                    .col-right {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            order: 2; } } }
                                // Botón
                                @extend %boton; }
                            // Bloque SEO
                            .parallax-content-inner {
                                display: block; }
                            .parallax-content {
                                -webkit-transition: max-height 1.5s ease;
                                overflow: hidden; }
                            .height {
                                max-height: 0px; }
                            .change {
                                &.height {
                                    max-height: 4000px; } }
                            .texto-seo {
                                min-height: size(55);
                                .wrap-with {
                                    position: relative;
                                    padding: 0 size(20);
                                    margin: 0 auto;
                                    max-width: $wrap;
                                    @include flex(center,nowrap,column,center,center);
                                    @media (min-width: $breakpoint1) {
                                        @include flex(center,nowrap,row,center,center); }
                                    .parallax-visible {
                                        @include flex(center,nowrap,row,center,center);
                                        text-align: center;
                                        width: 100%;
                                        padding: size(12);
                                        z-index: 100;
                                        #llamar-seo {
                                            width: 100%;
                                            @include flex(center,wrap,row,center,center);
                                            display: block;
                                            font-family: $fuente;
                                            font-size: size(21);
                                            line-height: size(24);
                                            font-weight: 700;
                                            color: $color4;
                                            text-decoration: none;
                                            color: $color4;
                                            &:hover {
                                                transition: color .3s linear;
                                                color: $color2;
                                                &::before,
                                                &::after {
                                                    transition: color .3s linear;
                                                    color: $color2; } }
                                            @media (min-width: $breakpoint1) {
                                                font-size: size(25);
                                                line-height: size(29); }
                                            .icon {
                                                margin-left: size(10); } } }
                                    .parallax-content {
                                        padding: 0 0.8em;
                                        z-index: 100; }
                                    .parallax-content-inner {
                                        padding: size(20) 0;
                                        z-index: 100;
                                        color: $ctexto;
                                        font-family: $fuente;
                                        font-weight: 400;
                                        font-size: size(18);
                                        line-height: size(25);
                                        p {
                                            margin: 0.8em 0 0.8em 0;
                                            text-align: justify; }
                                        a:link,
                                        a:visited {
                                            transition: color .3s linear;
                                            &:hover {
                                                color: $color4; } }
                                        h1,
                                        h2,
                                        h3,
                                        h4 {
                                            color: $ctexto; } } } }
                            // Editor 50 Slider
                            .bloque-editor-50-slider {
                                position: relative;
                                width: 100%;
                                margin: 0 auto;
                                padding: size(55) size(10);
                                .grid-flex {
                                    position: relative;
                                    z-index: 10;
                                    @include flex(center,wrap,column,center,center);
                                    padding: 0 size(20);
                                    @media (min-width: $breakpoint1) {
                                        padding: initial;
                                        @include flex(space-between,wrap,row,stretch,stretch); }
                                    .col {
                                        flex: 1;
                                        height: 100%;
                                        @media (min-width: $breakpoint1) {
                                            width: 50%; } }
                                    .col-text {
                                        @include flex(center,wrap,row,center,center);
                                        height: 100%; }
                                    .aligner-item {
                                        width: 100%;
                                        .aligner-item__titulo {
                                            position: relative;
                                            font-family: $fuente;
                                            font-size: size(25);
                                            line-height: size(30);
                                            font-weight: 700;
                                            color: $color4;
                                            @media (min-width: $breakpoint2) {
                                                font-size: size(30);
                                                line-height: size(36); }
                                            &::after {
                                                display: block;
                                                position: absolute;
                                                content: "";
                                                bottom: -15px;
                                                left: 0px;
                                                height: size(7);
                                                width: size(95);
                                                background-color: $color3; } }
                                        .aligner-item__descripcion {
                                            margin-top: size(30);
                                            font-family: $fuente;
                                            font-size: size(18);
                                            line-height: size(25);
                                            font-weight: 400;
                                            p {
                                                margin: size(18) 0;
                                                &:last-child {
                                                    margin-bottom: initial; } }
                                            // Listas
                                            ul {
                                                list-style: none;
                                                padding: 0;
                                                margin-left: 5px;
                                                li {
                                                    margin: 0.5em 0;
                                                    list-style: inherit;
                                                    &::before {
                                                        content: "\2022";
                                                        padding-right: 8px;
                                                        color: $color4;
                                                        font-size: size(23); } } }
                                            a:link,
                                            a:visited {
                                                &:hover {
                                                    color: $color4; } } } }
                                    .col-slider {
                                        width: 100%;
                                        height: auto;
                                        margin-top: size(44);
                                        @media (min-width: $breakpoint1) {
                                            margin-top: initial;
                                            padding: 0 size(10); }
                                        .slides {
                                            position: initial;
                                            margin-bottom: initial;
                                            .slick-list {
                                                .slick-track {
                                                    .slick-slide {
                                                        position: relative;
                                                        padding: size(20) size(25);
                                                        min-width: size(267);
                                                        @media (min-width: $breakpoint1) {
                                                            padding: size(30) size(35); }
                                                        .slide-halo {
                                                            overflow: hidden;
                                                            background: $white;
                                                            box-shadow: 0px 4px 42px rgba(188, 188, 188, 0.5);
                                                            border-radius: size(10);
                                                            padding: size(25);
                                                            transition: all .3s linear;
                                                            &:hover {
                                                                box-shadow: 0px 4px 42px rgba(0, 255, 204, 0.5); }
                                                            @media (min-width: $breakpoint1) {
                                                                padding: size(55) size(55); }
                                                            .slide-cabecera {
                                                                @include flex(flex-start,wrap,row,center,center);
                                                                .slide-cabecera__icono {
                                                                    margin-right: size(15);
                                                                    .slide-cabecera__img {
                                                                        min-width: size(45);
                                                                        margin-right: size(9); } }
                                                                .slide-cabecera__titulo {
                                                                    font-family: $fuente;
                                                                    font-size: size(20);
                                                                    line-height: size(23);
                                                                    font-weight: 700;
                                                                    color: $color4;
                                                                    @media (min-width: $breakpoint1) {
                                                                        font-size: size(20);
                                                                        line-height: size(23); } } }
                                                            .slide-descripcion {
                                                                display: block;
                                                                margin-top: size(16);
                                                                font-family: $fuente;
                                                                font-size: size(16);
                                                                line-height: size(23);
                                                                font-weight: 400;
                                                                color: $ctexto;
                                                                @media (min-width: $breakpoint1) {
                                                                    margin-top: size(19); } }
                                                            .slide-link {
                                                                position: relative;
                                                                @include flex(flex-star,nowrap,row,center,center);
                                                                width: 100%;
                                                                margin-top: size(10);
                                                                font-family: $fuente;
                                                                font-size: size(18);
                                                                line-height: size(25);
                                                                font-weight: 700;
                                                                text-decoration: none;
                                                                color: $color2;
                                                                transition: color .3s linear;
                                                                &::after {
                                                                    position: absolute;
                                                                    z-index: 10;
                                                                    left: size(175);
                                                                    display: inline-block;
                                                                    content: "";
                                                                    height: size(18);
                                                                    margin-left: size(5);
                                                                    background-color: $color3;
                                                                    width: 100%;
                                                                    @media (min-width: $breakpoint1) {
                                                                        left: size(200); } }
                                                                @media (min-width: $breakpoint1) {
                                                                    font-size: size(21);
                                                                    line-height: size(30);
                                                                    margin-top: size(19); }
                                                                &:hover {
                                                                    color: $color3; }
                                                                .icono {
                                                                    margin-left: size(8); } } } } } }
                                            .slick-dots {
                                                margin-top: size(10);
                                                left: 0px;
                                                @media (min-width: $breakpoint1) {
                                                    margin-top: size(20);
                                                    position: absolute;
                                                    left: 0px; }
                                                li {
                                                    button {
                                                        &::before {
                                                            content: "";
                                                            font-size: size(13);
                                                            line-height: size(13);
                                                            width: size(13);
                                                            height: size(13);
                                                            opacity: 1;
                                                            color: transparent;
                                                            border: 1px solid #D8D8D8;
                                                            border-radius: 50%; } } }
                                                .slick-active {
                                                    button {
                                                        &::before {
                                                            background-color: #D8D8D8; } } } } } }
                                    .col-left {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            margin-right: size(20);
                                            width: 50%; } }
                                    .col-right {
                                        order: 1;
                                        @media (min-width: $breakpoint1) {
                                            margin-left: size(20);
                                            width: 50%;
                                            order: 2; } } } }
                            // Bloque Center
                            .b-bloque-center {
                                display: block;
                                width: 100%;
                                height: 100%;
                                min-height: size(300);
                                @media (min-width: $breakpoint1) {
                                    min-height: size(450);
                                    max-height: size(450); }
                                .bloque-center-contenedor {
                                    display: block;
                                    position: relative;
                                    width: 100%;
                                    max-width: $wrap;
                                    height: 100%;
                                    margin: 0 auto;
                                    min-height: size(300);
                                    @media (min-width: $breakpoint1) {
                                        min-height: size(450);
                                        max-height: size(450); }
                                    .bloque-center-contenido-izquierda {
                                        position: absolute;
                                        padding: 0 size(20);
                                        top: 50%;
                                        left: 0;
                                        transform: translate(0, -50%);
                                        z-index: 100;
                                        width: 100%;
                                        @media (min-width: $breakpoint2) {
                                            width: 50%; }
                                        @extend %bloque-center-contenido; }
                                    .bloque-center-contenido-centrado {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        width: 100%;
                                        @media (min-width: $breakpoint2) {
                                            width: 50%; }
                                        transform: translate(-50%, -50%);
                                        z-index: 100;
                                        @extend %bloque-center-contenido; }
                                    .bloque-center-contenido-derecha {
                                        position: absolute;
                                        top: 50%;
                                        right: 0;
                                        width: 100%;
                                        @media (min-width: $breakpoint2) {
                                            width: 50%; }
                                        transform: translate(0, -50%);
                                        z-index: 100;
                                        @extend %bloque-center-contenido; }
                                    %bloque-center-contenido {
                                        .bloque-titulos {
                                            padding: 0 size(20);
                                            .titulos {
                                                .titulo {
                                                    display: block;
                                                    font-family: $fuente;
                                                    font-weight: 700;
                                                    font-size: size(24);
                                                    color: $white;
                                                    line-height: size(30);
                                                    @media (min-width: $breakpoint1) {
                                                        font-size: size(30);
                                                        line-height: size(34); } } } }
                                        .descripcion {
                                            margin-top: size(20);
                                            font-family: $fuente;
                                            font-weight: 300;
                                            font-size: size(12);
                                            line-height: size(26);
                                            color: $white;
                                            text-align: justify;
                                            padding: 0 size(20);
                                            p {
                                                margin-bottom: initial; }
                                            @media (min-width: $breakpoint1) {
                                                font-size: size(14);
                                                line-height: size(30); } }
                                        // Botón
                                        .botones {
                                            @include flex(center,wrap,row,center,center);
                                            padding: 0 size(20);
                                            @media (min-width: $breakpoint1) {
                                                @include flex(flex-start,wrap,row,center,center); }
                                            // Botón
                                            @extend %boton;
                                            .boton {
                                                &:last-of-type {
                                                    margin-left: size(20); } } } } } }
                            // Bloque circulos
                            .bloque-circulos-contenedor {
                                padding: size(50) size(20);
                                @media (min-width: $breakpoint1) {
                                    padding: 0 size(10); }
                                .bloque-circulos__titulo {
                                    margin-bottom: size(40);
                                    font-family: $fuente;
                                    font-weight: 700;
                                    font-size: size(25);
                                    line-height: size(29);
                                    text-align: center;
                                    color: $color4;
                                    @media (min-width: $breakpoint1) {
                                        margin-bottom: size(34);
                                        font-size: size(30);
                                        line-height: size(35); } }
                                .bloque-circulos {
                                    display: block;
                                    padding: 0 size(20);
                                    .circulos {
                                        @include flex(center,wrap,row,center,center);
                                        width: 100%;
                                        text-align: center;
                                        margin: 0 auto;
                                        max-width: $wrap;
                                        .circulo {
                                            position: relative;
                                            min-height: size(150);
                                            @media (min-width: $breakpoint1) {
                                                box-sizing: content-box;
                                                min-height: size(230); }
                                            &:nth-of-type(2n+1) {
                                                .centrado {
                                                    .spinner {
                                                        &::after {
                                                            position: absolute;
                                                            z-index: 100;
                                                            content: "";
                                                            width: 100%;
                                                            height: 100%;
                                                            top: 0;
                                                            left: 0;
                                                            width: size(132);
                                                            height: size(132);
                                                            border-top: size(12) solid transparent;
                                                            border-right: size(12) solid transparent;
                                                            border-bottom: size(12) solid $color3;
                                                            border-left: size(12) solid $color3;
                                                            border-radius: 50%;
                                                            -webkit-animation: loading 6s linear infinite;
                                                            animation: loading 6s linear infinite;
                                                            @media (min-width: $breakpoint1) {
                                                                -webkit-animation: loading 6s linear infinite;
                                                                animation: loading 6s linear infinite;
                                                                width: size(208);
                                                                height: size(208);
                                                                border-top: size(20) solid transparent;
                                                                border-right: size(20) solid transparent;
                                                                border-bottom: size(20) solid $color3;
                                                                border-left: size(20) solid $color3; } } } } }
                                            .circulo-titulo {
                                                display: inline-block;
                                                position: absolute;
                                                z-index: 10000;
                                                top: 45%;
                                                transform: translate(-50%,-45%);
                                                font-family: $fuente;
                                                font-size: size(14);
                                                line-height: size(20);
                                                font-weight: bold;
                                                text-align: center;
                                                color: $color4;
                                                @media (min-width: $breakpoint1) {
                                                    top: 50%;
                                                    left: 60%;
                                                    transform: translate(-50%,-50%);
                                                    font-size: size(18);
                                                    line-height: size(22); } }
                                            .centrado {
                                                position: relative;
                                                @include flex(center,nowrap,row,center,center);
                                                .spinner {
                                                    width: size(132);
                                                    height: size(132);
                                                    border-radius: 50%;
                                                    transform: rotate(-45deg);
                                                    @media (min-width: $breakpoint1) {
                                                        width: size(208);
                                                        height: size(208);
                                                        padding: 0 size(10); }
                                                    &::before {
                                                        position: absolute;
                                                        z-index: 10;
                                                        content: "";
                                                        top: 0;
                                                        left: 0;
                                                        width: size(132);
                                                        height: size(132);
                                                        border-top: size(12) solid #F5F5F5;
                                                        border-right: size(12) solid #F5F5F5;
                                                        border-bottom: size(12) solid #F5F5F5;
                                                        border-left: size(12) solid #F5F5F5;
                                                        border-radius: 50%;
                                                        @media (min-width: $breakpoint1) {
                                                            width: size(208);
                                                            height: size(208);
                                                            border-top: size(20) solid #F5F5F5;
                                                            border-right: size(20) solid #F5F5F5;
                                                            border-bottom: size(20) solid #F5F5F5;
                                                            border-left: size(20) solid #F5F5F5; } }
                                                    &::after {
                                                        position: absolute;
                                                        z-index: 100;
                                                        content: "";
                                                        top: 0;
                                                        left: 0;
                                                        width: size(132);
                                                        height: size(132);
                                                        border-top: size(12) solid $color3;
                                                        border-right: size(12) solid $color3;
                                                        border-bottom: size(12) solid transparent;
                                                        border-left: size(20) solid transparent;
                                                        border-radius: 50%;
                                                        -webkit-animation: loading-reverse 6s linear infinite;
                                                        animation: loading-reverse 6s linear infinite;
                                                        @media (min-width: $breakpoint1) {
                                                            -webkit-animation: loading-reverse 3s linear infinite;
                                                            animation: loading-reverse 3s linear infinite;
                                                            width: size(208);
                                                            height: size(208);
                                                            border-top: size(20) solid $color3;
                                                            border-right: size(20) solid $color3;
                                                            border-bottom: size(20) solid transparent;
                                                            border-left: size(20) solid transparent; } } } } } } } }
                            // Bloque iconos
                            .bloque-iconos-contenedor {
                                padding: 0 size(20);
                                @include flex(center,nowrap,column,center,center);
                                @media (min-width: $breakpoint1) {
                                    padding: 0 size(10); }
                                .bloque-iconos__titulo {
                                    margin-bottom: size(29);
                                    font-family: $fuente;
                                    font-weight: bold;
                                    font-size: size(20);
                                    line-height: size(23);
                                    text-align: center;
                                    color: $color4;
                                    @media (min-width: $breakpoint1) {
                                        margin-bottom: size(36);
                                        font-size: size(25);
                                        line-height: size(29); } }
                                .bloque-iconos__texto {
                                    margin-bottom: size(29);
                                    margin-top: size(29);
                                    font-family: $fuente;
                                    font-weight: 400;
                                    font-size: size(18);
                                    line-height: size(25);
                                    color: $ctexto; }
                                .bloque-iconos {
                                    display: block;
                                    padding: 0 size(20);
                                    @media (min-width: $breakpoint1) {
                                        width: 80%; }
                                    .iconos {
                                        display: table;
                                        table-layout: fixed;
                                        width: 100%;
                                        .slick-list {
                                            .slick-track {
                                                .iconor {
                                                    position: relative;
                                                    text-align: center;
                                                    %iconor-contenido {
                                                        .iconor-cabecera {
                                                            position: relative;
                                                            z-index: 1;
                                                            .iconor-img {
                                                                display: block;
                                                                object-fit: contain;
                                                                min-width: size(130);
                                                                max-width: size(130);
                                                                filter: grayscale(100%);
                                                                &:hover {
                                                                    transition: all .2s linear;
                                                                    filter: grayscale(0%); } } } }
                                                    .iconor-contenido {
                                                        @include flex(center,nowrap,column,center,center);
                                                        @extend %iconor-contenido; } } } }
                                        .slick-dots {
                                            margin-top: size(40);
                                            @media (min-width: $breakpoint1) {
                                                margin-top: size(20);
                                                position: absolute;
                                                left: 0px; }
                                            li {
                                                button {
                                                    &::before {
                                                        content: "";
                                                        font-size: size(13);
                                                        line-height: size(13);
                                                        width: size(13);
                                                        height: size(13);
                                                        opacity: 1;
                                                        color: transparent;
                                                        border: 1px solid #D8D8D8;
                                                        border-radius: 50%; } } }
                                            .slick-active {
                                                button {
                                                    &::before {
                                                        background-color: #D8D8D8; } } } } } } }
                            // Bloque Editor
                            .bloque-editor-texto {
                                position: relative;
                                padding: 0 size(20);
                                .titulos {
                                    .titulo {
                                        color: $color4; } }
                                .texto {
                                    font-family: $fuente;
                                    font-size: size(18);
                                    line-height: size(25);
                                    font-weight: 400;
                                    p {
                                        margin: size(18) 0;
                                        &:first-child {
                                            margin-top: initial; }
                                        &:last-child {
                                            margin-bottom: initial; } }
                                    // Listas
                                    ul {
                                        list-style: none;
                                        padding: 0;
                                        margin-left: 5px;
                                        li {
                                            margin: 0.5em 0;
                                            list-style: inherit;
                                            &::before {
                                                content: "\2022";
                                                padding-right: 8px;
                                                color: $color4;
                                                font-size: size(23); } } }
                                    a:link,
                                    a:visited {
                                        &:hover {
                                            color: $color4; } } }
                                // Botón
                                @extend %boton; }
                            // Bloque shortcode
                            .bloque-shortcode {
                                width: 100%;
                                text-align: center;
                                margin: size(30) 0 size(40) 0;
                                @media (min-width: $breakpoint1) {
                                    margin: size(60) 0 size(80) 0; }
                                .titulos {
                                    width: 100%;
                                    margin: 0 auto size(60) auto;
                                    max-width: $wrap;
                                    text-align: center;
                                    &::after {
                                        left: 50%;
                                        transform: translate(-50%); } }
                                .shortcode {
                                    width: 100%;
                                    // Grid Proyectos
                                    .grid-casos-de-exito {
                                        .grid-categorias-bloque {
                                            width: 100%;
                                            @include flex(stretch,wrap,row,stretch,stretch);
                                            @media (min-width: $breakpoint1) {
                                                display: inline-flex;
                                                flex-wrap: wrap;
                                                justify-content: center;
                                                margin: calc(-1 * 20px) 0 0 calc(-1 * 20px);
                                                width: calc(100% + 20px);
                                                > * {
                                                    margin: 20px 0 0 20px; } }
                                            .custom-grid__item {
                                                @include flex(center,nowrap,column,center,center);
                                                width: 100%;
                                                margin-top: size(55);
                                                flex: 1 1 100%;
                                                &:first-child {
                                                    margin-top: size(24); }
                                                &:last-child {
                                                    margin-bottom: size(60); }
                                                @media (min-width: $breakpoint1) {
                                                    flex: 1 1 31%;
                                                    margin-top: size(42);
                                                    &:first-child {
                                                        margin-top: size(42); }
                                                    &:last-child {
                                                        margin-bottom: initial; } }
                                                .custom-grid-item-content__link {
                                                    display: inline-block;
                                                    text-decoration: none;
                                                    font-size: size(21);
                                                    line-height: size(24);
                                                    color: $color4;
                                                    transition: color .3s linear;
                                                    @media (min-width: $breakpoint1) {
                                                        font-size: size(25);
                                                        line-height: size(29); }
                                                    &:hover {
                                                        color: $color3; } }
                                                .custom-grid-item-content__linkado {
                                                    display: inline-block;
                                                    position: relative;
                                                    display: block;
                                                    margin-top: size(10);
                                                    @media (min-width: $breakpoint1) {
                                                        margin-top: initial; }
                                                    figure {
                                                        position: relative;
                                                        width: 100%;
                                                        height: 100%;
                                                        overflow: hidden;
                                                        img {
                                                            -webkit-transform: scale(1);
                                                            transform: scale(1);
                                                            -webkit-transition: 1s ease-in-out;
                                                            transition: 1s ease-in-out;
                                                            display: block;
                                                            filter: grayscale(0); }
                                                        &:hover {
                                                            .custom-grid__item__img {
                                                                -webkit-transform: scale(1.2);
                                                                transform: scale(1.2);
                                                                filter: grayscale(100); } }
                                                        .custom-grid__item__img {
                                                            display: block;
                                                            overflow: hidden;
                                                            width: 100%;
                                                            min-height: size(250);
                                                            max-height: size(250);
                                                            object-fit: cover;
                                                            object-position: center; } } }
                                                .custom-grid__item__content {
                                                    display: block;
                                                    width: 100%;
                                                    margin: size(20) auto 0 auto;
                                                    @media (min-width: $breakpoint1) {
                                                        margin: size(23) auto size(20) auto; }
                                                    .custom-grid-item-content__datos {
                                                        @include flex(center,nowrap,column,center,center); } }
                                                .custom-grid-item-content__button {
                                                    display: inline-block;
                                                    @include flex(center,nowrap,row,center,center);
                                                    margin-top: size(20);
                                                    font-family: $fuente;
                                                    font-size: size(21);
                                                    line-height: size(30);
                                                    font-weight: bold;
                                                    text-decoration: none;
                                                    color: $color2;
                                                    transition: color .3s linear;
                                                    &:hover {
                                                        color: $color3; }
                                                    .icono {
                                                        margin-left: size(4); } } } } } } } } } } } } } }
/*************** ANIMACIONES ***********************/
@-webkit-keyframes loading {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }
@keyframes loading {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); } }

@-webkit-keyframes loading-reverse {
    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); }
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); } }
@keyframes loading-reverse {
    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); }
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); } }
